@import "~styles/_variables.scss";
@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";

#calculator .explanation .card {
  h4 {
    margin-top: 1em;
    margin-bottom: 0.75em;
  }

  code {
    margin-left: 0.2em;
  }

  #changelog {
    color: $secondary;
    font-style: italic;
    margin-top: 0.5em;
    margin-bottom: 1em;
  }

  #calculation-explanation-body {
    border: 1px solid $border-color;
    padding: 1em;
    margin: 1em 0;
    background-color: $gray-100;

    #calculation-breakdown {
      margin-left: 2em;
    }

    li {
      margin-bottom: 0.5em;
    }
  }

  #additional-precautions {
    li {
      margin-bottom: 0.2em;
    }
  }
}
