@import '~styles/_variables.scss';
@import "~rfs/scss";
@import "~bootstrap/scss/mixins/_breakpoints.scss";

.paperPage {
  font-size: 18px;
  line-height: 30px;
  max-width: 850px;

  h1 { color: $primary}

  h1,h2,h3,h4,h5,h6 {
    // Flip text and anchor links left-to-right
    display: flex;
    justify-content: flex-end;
    flex-direction: row-reverse;

    a {
      opacity: 0;
    }

    &:hover {
      a {
        display: initial;
        opacity: 1;
        padding-left: 5px;
      }
    }
  }

  h3 {
    font-weight: normal;
  }

  p, ul, ol {
    margin-bottom: 1.6rem;
    ul, ol {
      // Don't add margin for nested lists
      margin-bottom: 0rem
    }
  }

  

  .warning {
    border: 2px solid $warning-border;
    padding: 10px;
    margin: 2.4rem -12px 1.6rem -12px;
    background-color: $warning-fill;
    h2:first-child { margin: 0; }
    p:last-child { margin-bottom: 0; }
  }

  code {
    color: $cyan;
    white-space: pre-wrap;
  }

  pre {
    background-color: #fff;
    border: 1px solid #aaa;
    padding: 1em;
    margin: 1em;
  }

  .pageTitle {
    margin-top: 0;
    margin-bottom: 0;
  }
  

  .postByLine {
    color: $gray-700;
    margin-top: 8px;
    margin-bottom: 15px;
  }

  .postAuthor {
    margin-top: 10px;
  }

  .sectionIndicator {
    margin-bottom: 0;
    color: $gray-500;
    font-size: 18px;
    font-weight: 700;
    font-family: $font-family-sans-serif;
  }

  .navigation {
    margin: 30px 0;
    display: flex;
    justify-content: space-between;

    a {
      color: $gray-800;
      text-decoration: none;

      &:hover {
        color: $primary;
        text-decoration: underline;
      }
    }

    .next {
      text-align: right;
    }
  }

  table {
    margin: 40px 0;
    table-layout: fixed;
  }

  th {
    border-bottom: 1px solid $gray-500;
    background-color: $purple;
    color: white;
  }

  td, th {
    border-right: 1px solid $gray-500;
    padding: 10px;
    @include media-breakpoint-up(md) {
      min-width: 200px;
    }

    &:last-child {
      border-right: none;
    }
  }

  tr:nth-child(2n) {
    background: $gray-200;
  }

  .calloutText {
    text-align:center;
    font-size:1.5em;
    line-height: 1.5em;
    margin: 30px 50px;
    padding: 15px;
    border-radius: 10px;
    background: #eee;
  }

  ol.toc {
    margin: 20px 0;

    a {
      padding-left: 3px;
      text-decoration: none;
    }
  }

  hr.footnotes-sep {
    margin: 40px 0;
  }
  section.footnotes {
    font-size: 14px;
    max-width: 600px;
  }

  img {
    max-width: 100%;
    height: auto;
    margin-bottom: 1.6rem;
  }

  figcaption {
    font-style: italic;
    font-size: 16px;
    color: darkgray;
  }

  blockquote {
    background-color: $gray-200;
    margin-left: .5em;
    padding: .2em;
    padding-left: 1em;
    border-radius: 8px;

    p {
      font-style: italic;
      margin: 0em;
      padding: .5em;
      border-color: darkgray;
      border-width: 0px;
      border-left-width: 2px;
      border-style: solid;
    }
  }

  .calendar {
    td, th {
        min-width: 20px;
    }
  }
  
  .card {
    border: none;
    background: lighten($gray-200, 1.5);
    border-radius: 8px;

    header {
      color: white;
      font-weight: bold;
      padding: 10px;
      border-radius: 8px;
      margin-bottom: 20px;
      background: $primary;
      @include font-size(1.2rem);
    }

    // Card subheader
    .subheading {
      border-bottom: 1px solid $gray-300;
      font-style: italic;
    }

    .col {
      margin-left: 10px
    }

    .hepa-result {
      border: none;
      border-radius: 6px;
      background: #f8f8fb;
      font-weight: 700;
      height: calc(2em + 2rem + 2px);
      width: 90%;
      padding-top: .25em;
      text-align: center;
    }
  }
  iframe {
    width: 100%; 
    height: 600px; 
    border: 0px none;
  }
}
