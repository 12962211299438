@import "~styles/_variables.scss";
@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~rfs/scss";

// Tooltip button/badge styles
.form-group {
  .label-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  label {
    @include font-size($input-font-size-lg);
  }

  .badge {
    cursor: pointer;
    border-radius: 12px;
    width: 20px;
    margin-left: 15px;
  }

  .field-label {
    @include font-size($input-font-size-sm);
  }
}
