// Override default variables before the import
@import '~styles/_variables.scss';
@import "~bootstrap/scss/mixins/_breakpoints.scss";
@import "~rfs/scss";

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

@font-face {
  font-family: 'LatoLocal';
  src: url('fonts/lato-normal.woff2') format('woff2'),
       url('fonts/lato-normal.woff') format('woff'),
       url('fonts/lato-normal.ttf')  format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'LatoLocal';
  src: url('fonts/Lato-Bold.woff2') format('woff2'),
       url('fonts/Lato-Bold.woff') format('woff'),
       url('fonts/Lato-Bold.ttf')  format('truetype');
  font-weight: bold;
  font-style: normal;
}


@font-face {
  font-family: 'LatoLocal';
  src: url('fonts/Lato-Italic.woff2') format('woff2'),
       url('fonts/Lato-Italic.woff') format('woff'),
       url('fonts/Lato-Italic.ttf')  format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'LatoLocal';
  src: url('fonts/Lato-BoldItalic.woff2') format('woff2'),
       url('fonts/Lato-BoldItalic.woff') format('woff'),
       url('fonts/Lato-BoldItalic.ttf')  format('truetype');
  font-weight: bold;
  font-style: italic;
}



html, body, #root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
}

#root {
  justify-content: space-between;
  overflow: hidden;
}

input,
select {
  color: $gray-800 !important;
}

a {
  text-decoration: underline;
}

a.btn  {
  text-decoration: none;
}
  
.alert a {
  font-weight: 700;
}

.alert-info a {
  color: #0c596c;
}

.alert-secondary a {
  color: #404848;
}

h1,h2,h3,h4,h5,h6 {
  font-family: 'Roboto Slab';
  font-weight: 700;
  margin-bottom: 1.3rem;
}

.footer {
  width: 100%;
  line-height: 1.2;
  padding: 30px 0;
  margin-top: 20px;
  background-color: $gray-300;
  border-top: 1px solid $gray-400;

  p {
    margin: 10px 0;
  }
}

// Layout overrides

.navbar {
  padding-left: 0;
  padding-right: 0;

  .navbar-brand {
    @include media-breakpoint-down(md) {
      font-size: 25px;
    }
  }
  a {
    text-decoration: none;
  }
}

.navbar-brand {
  font-family: 'Roboto Slab';
  font-size: 30px;
}

.nav-link {
  padding: 0.5rem !important;
  margin: 0;

  border-bottom: 2px solid transparent;
  letter-spacing: 1px;

  &.active {
    border-left: 2px solid $primary;
  }

  @media (min-width: 768px) {
    padding: 0.5rem 0 !important;
    margin: 0 0.5rem;

    &.active {
      border-left: none;
      border-bottom: 2px solid $primary;
    }
  }

}

.warning {
  border: 2px solid $warning-border;
  padding: 10px;
  margin: 0 -12px 1.5rem -12px;
  background-color: $warning-fill;

  h2 { margin: 0; }
}

// Home page

.accent {
  line-height: 1em;
  overflow: hidden;
  font-weight: bold;
}
.accent span {
  display: inline-block;
  position: relative;  
  padding-bottom: .3em;
}
.accent span:after {
  content: "";
  position: absolute;
  height: 50%;
  border-bottom: 1px solid #aaa;
  top: 0;
  width: 9999px;
}
.accent span:after {
  left: 100%;
  margin-left: 15px;
}
