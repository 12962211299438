.slider-component {
  font-size: 1.1em;
  margin-top: 50px;
  margin-bottom: 100px;
}

.slider-label {
    font-size: 1.4em;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    width: 125px;
    font-weight: bold;
    padding-top: 20px;
    padding-bottom: 20px;
}

$breakpoint-desktop: 992px;
$breakpoint-tablet: 768px;
$breakpoint-mobile: 576px;

.preset-buttons {
  width: 50%;
}

@media (max-width: $breakpoint-desktop) {
    .slider-label {
        display: inline-block;
        width: 150px;
    }

    .preset-buttons {
      width: 100%;
    }
}

@media (max-width: $breakpoint-tablet) {
  .slider-component {
    margin-bottom: 50px !important;
  }

  .slider-label {
    font-size: 0em;
    display: inline-block;
    width: 100px;
  }
}

@media (max-width: $breakpoint-mobile) {

    .slider-label {
      display: inline-block;
      width: 75px;
    }
}