// Breakpoints

$grid-breakpoints: (
  xs: 0,
  sm: 480px,
  md: 768px,
  lg: 1150px,
  xl: 1300px,
);

$container-max-widths: (
  sm: 600px,
  md: 900px,
  lg: 1050px,
  xl: 1200px,
);

// Bootswatch: Flatly

$white: rgb(248, 248, 251) !default;
$gray-100: #f8f9fa !default;
$gray-200: #ecf0f1 !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #b4bcc2 !default;
$gray-600: #95a5a6 !default;
$gray-700: #7b8a8b !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$blue: #2c3e50 !default;
$indigo: #6610f2 !default;
$purple: #551CA0 !default;
$pink: #e83e8c !default;
$red: #e74c3c !default;
$orange: #fd7e14 !default;
$yellow: #f39c12 !default;
$green: #18bc9c !default;
$teal: #20c997 !default;
$cyan: #18ACD0 !default;


$thermometer-dark-red: #c7022a;
$thermometer-red: #ff0034;
$thermometer-orange: #ff9600;
$thermometer-yellow: #ffc900;
$thermometer-light-green: #aacd27;
$thermometer-green: #00d474;

$microcov-brand: #D018A1;


$primary: $microcov-brand !default;
$secondary: $gray-700 !default;
$success: $microcov-brand !default;
$info: $cyan !default;
$warning-border: $yellow !default;
$warning-fill: #fbdfb1 !default;
$danger: $red !default;
$light: $gray-200 !default;
$dark: $gray-700 !default;

$theme-colors: (
  teal: $teal,
  cyan: $cyan
);

$yiq-contrasted-threshold: 175 !default;

// Text

$body-color: #444;

$enable-responsive-font-sizes: 1;

// Links

$link-color: $success !default;

// Fonts

$font-family-sans-serif: 'LatoLocal', -apple-system, BlinkMacSystemFont, 'Segoe UI',
  Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol' !default;

$font-size-base: 0.9375rem !default;

$h1-font-size: 2rem !default;
$h2-font-size: 1.5rem !default;
$h3-font-size: 1.25rem !default;
$h4-font-size: 1.15rem !default;

$headings-font-weight: 700;

// Tables

$table-accent-bg: $gray-200 !default;

// Dropdowns

$dropdown-link-color: $gray-700 !default;
$dropdown-link-hover-color: $white !default;
$dropdown-link-hover-bg: $primary !default;

// Navs

$nav-link-padding-y: 0.5rem !default !default;
$nav-link-padding-x: 2rem !default;
$nav-link-disabled-color: $gray-600 !default !default;

$nav-tabs-border-color: $gray-200 !default;

// Navbar

$navbar-padding-y: 1rem !default;

$navbar-dark-color: $white !default;
$navbar-dark-hover-color: $success !default;

// Pagination

$pagination-color: $white !default;
$pagination-bg: $success !default;
$pagination-border-width: 0 !default;
$pagination-border-color: transparent !default;

$pagination-hover-color: $white !default;
$pagination-hover-bg: darken($success, 15%) !default;
$pagination-hover-border-color: transparent !default;

$pagination-active-bg: $pagination-hover-bg !default;
$pagination-active-border-color: transparent !default;

$pagination-disabled-color: $gray-200 !default;
$pagination-disabled-bg: lighten($success, 15%) !default;
$pagination-disabled-border-color: transparent !default;

// List group

$list-group-hover-bg: $gray-200 !default;

$list-group-disabled-bg: $gray-200 !default;

// Close

$close-color: $white !default;
$close-text-shadow: none !default;
